@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "./_metronic/_assets/sass/style.react.scss";

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;

  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.headerToolbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .headerButton {
    cursor: pointer;
    border-radius: 5px;
    padding: 5px 15px;

    &:hover {
      background-color: #f5f5f5;
    }

    span {
      display: inline-block;
      margin-left: 10px;
      color: #B5B5C3;
    }
  }
}

.basicModal {
  top: calc(50vh - 260px);
  left: calc(50% - 160px);
  width: 320px;
  border-radius: 5px;
  outline: none;
  position: absolute;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  background-color: #fff;

  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    background-color: transparent;
    padding: 20px 30px;
    margin-bottom: 0;
    border-bottom: 1px solid #ebedf3;
    font-weight: 500;
    font-size: 1.275rem;
    color: #181c32;

    .modalTitle {
      display: flex;
      align-items: center;
    }

    .modalToolbar {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
    }

    .closeButton {
      cursor: pointer;
    }
  }

  .modalBody {
    padding: 20px;
  }

  .modalFooter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: transparent;
    padding: 20px 30px;
    margin-top: 20px;
    border-top: 1px solid #ebedf3;
    font-weight: 500;
    font-size: 1.275rem;
    color: #181c32;
  }
}

.publishAdd {
  color: #017abb;
  cursor: pointer;
  display: flex;
  padding: 15px;
  font-size: 24px;
  min-height: 120px;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #eef0f8;
}

.publishAdd:hover {
  opacity: 0.7;
}

.alertFooter {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-top: 10px;
  border-top: 1px solid #ddd;
  margin-top: 20px;
  margin-bottom: -20px;
}

.alertTitle {
  display: flex;
  width: 100%;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  margin-top: -10px;
  font-size: 14px;
  font-weight: 700;
}

.publishContainer {
  display: flex;
  flex-wrap: wrap;

  h3 {
    margin-bottom: 30px;
    color: #6a92fb;

    span {
      font-weight: normal;
    }
  }

  .col-md-2 {
    margin-bottom: 20px;
  }

  .publishItem {
    background-color: rgba(200, 247, 245, 0.3);
    height: 100%;
    border-radius: 15px;
    overflow: hidden;
    margin-bottom: 30px;
  }

  .publishHeader {
    display: flex;
    flex-direction: row;
    border-radius: 15px 15px 0 0;
    background: #c8f7f5;
    z-index: 10;
    margin-bottom: 3px;
    border-bottom: 1px solid #fff;
    justify-content: space-between;

    .publishHeaderButtons {
      display: flex;
      flex-direction: row;

      .headerButton {
        margin-left: 3px;
        cursor: pointer;

        &.danger {
          padding: 5px;

          g [fill] {
            fill: #dc3545 !important;
          }
        }

        &.success {
          padding: 7px 5px 3px 5px;

          g [fill] {
            fill: #ffb244 !important;
          }
        }
      }
    }
  }
}

.publish {
  display: flex;
  flex-direction: column;
  cursor: pointer;

  .publishers {
    height: 100%;
    margin-bottom: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.publishGroup {
  color: #017abb;
  cursor: pointer;
  display: flex;
  padding: 15px;
  font-size: 24px;
  min-height: 120px;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #017abb;
}

.publishGroup:hover {
  opacity: 0.7;
}

.publishDesc {
  margin-top: 20px;
  min-height: 60px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.publishImage {
  width: 100%;
}

.publishAddIcon {
  font-size: 52px;
}

.publishAddText {
  font-size: 14px;
}

.closePublish {
  display: none !important;
}

.cursorPointer {
  cursor: pointer;
}

.publishAddContainer {
  display: flex;
  width: 100%;
  margin: 0;

  .imgClose {
    position: absolute;
    top: -10px;
    right: -10px;
  }

  label {
    cursor: pointer;
  }

  .imageSelect {
    display: flex;
    flex-direction: column;
    align-items: center;

    input {
      display: none;
    }
  }

  .imgView {
    text-align: center;
    margin-bottom: 20px;

    .imgTop {
      width: 180px;
      min-height: 180px;
      border-radius: 0.42rem;
      background-repeat: no-repeat;
      background-size: cover;
      border: 3px solid #ffffff;
      -webkit-box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075);
      box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075);
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
      }
    }
  }

  .imgViewAdmin {
    text-align: center;
    margin-bottom: 20px;

    .imgTop {
      width: 180px;
      min-height: 180px;
      border-radius: 0.42rem;
      background-repeat: no-repeat;
      background-size: cover;
      border: 3px solid #ffffff;
      box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075);
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      img {
        width: 100%;
      }
    }
  }

  .imgBottom {
    position: relative;
    display: inline-block;
    border-radius: 0.42rem;
    background-repeat: no-repeat;
    background-size: cover;

    .inputContainer {
      position: relative;
      padding-top: 15px;

      .MuiFormControl-root {
        margin-left: 0;

        label {
          min-width: 250px;
          font-size: 16px;
          margin-bottom: 0px;
        }

        input:before {
          border:0;
        }

        label + .MuiInput-formControl {
          margin-top: 30px;
        }

        .MuiInput-underline:before {
          border-bottom: 0;
        }
      }

      label {
        top: 0;
        left: 0;
        position: absolute;
        color: rgba(0, 0, 0, 0.45);
        padding: 0;
        font-size: 1rem;
        font-family: Poppins;
        font-weight: 400;
        line-height: 1;
      }

      &:before {
        left: 0;
        right: 0;
        bottom: 0;
        content: "\00a0";
        position: absolute;
        transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        border-bottom: 1px solid rgba(0, 0, 0, 0.42);
        pointer-events: none;
      }

      input {
        outline: none;
        font: inherit;
        color: currentColor;
        width: 100%;
        border: 0;
        height: 1.1876em;
        margin: 0;
        display: block;
        padding: 6px 0 7px;
        min-width: 0;
        background: none;
        box-sizing: content-box;
        animation-name: mui-auto-fill-cancel;
        letter-spacing: inherit;
        -webkit-tap-highlight-color: transparent;
      }
    }

    .checkContainer {
      position: relative;
      padding-top: 15px;

      .checkLesson {
        display: inline-block;
        margin-right: 20px;
        cursor: pointer;
        margin-bottom: 5px;
      }

      label {
        top: 0;
        left: 0;
        position: absolute;
        color: rgba(0, 0, 0, 0.45);
        padding: 0;
        font-size: 1rem;
        font-family: Poppins;
        font-weight: 400;
        line-height: 1;
      }

      .checkRow {
        position: relative;
        margin-top: 5px;
      }

      .checkIcon svg {
        width: 12px;
        height: 12px;
      }
    }
  }
}

.spinnerTop {
  display: inline-flex;
  width: 30px;

  &:before {
    margin-top: -1rem;
  }
}

.excelContainer {
  width: 100%;
  overflow-y: auto;
}

.excelTable {
  .excelHeader {
    display: flex;
    position: sticky;
    top: 0;
    height: 30px;
    background: #0180b5;
    color: #fff;
  }

  .excelTest {
    display: flex;
  }

  .testAdd {
    width: 110px;
    position: absolute;
    text-align: right;
    bottom: 0;
    height: 2px;
    cursor: pointer;

    .testPlus {
      position: absolute;
      bottom: 0;
      right: 3px;
      font-size: 10px;
    }

    .testAddText {
      display: none;
      font-size: 9px;
      font-style: normal;
      position: absolute;
      right: 18px;
      bottom: -2px;
    }

    &:hover .testAddText{
      display: inline-block;
    }
  }
  .excelRow {
    display: flex;

    &:nth-child(even) {
      background-color: rgba(0, 0, 0, 0.025);
    }
  }

  .excelTest {
    &:nth-child(even) {
      background-color: rgba(255, 0, 0, 0.025);
    }
  }

  strong {
    display: inline-flex;
    border-right: 1px solid #eee;
    margin-left: -1px;
    justify-content: center;
    align-items: center;
    padding: 2px;
    height: 30px;
  }

  span {
    display: inline-flex;
    border: 1px solid #eee;
    justify-content: center;
    align-items: center;
    padding: 2px;
    margin-left: -1px;
    margin-top: -1px;
    cursor: pointer;
  }

  input {
    display: inline-flex;
    border: 1px solid #eee;
    justify-content: center;
    align-items: center;
    padding: 2px;
    margin-left: -1px;
    margin-top: -1px;
    cursor: pointer;
    text-align: center;
    outline: none;
  }

  textarea {
    border: 1px solid #eee;
    justify-content: center;
    align-items: center;
    padding: 2px;
    margin-left: -1px;
    margin-top: -1px;
    cursor: pointer;
    outline: none;
    height: auto;

    box-sizing: border-box;
    display: flex;
  }

  .konuAdi {
    display: flex;
    flex: 1;
    min-width: 135px;
    justify-content: flex-start;
    border-left: 0;
    min-height: 24px;
    align-items: flex-start !important;
  }

  .soruNumarasi {
    display: flex;
    flex: 1;
    min-width: 90px;
    justify-content: flex-start;
    border-left: 0;
    min-height: 24px;
    align-items: flex-start !important;
  }

  .testAdi {
    width: 110px;
    justify-content: flex-start;
    align-items: flex-start !important;
    min-height: 24px;
    text-align: left;
  }

  .testSure {
    width: 65px;
    min-height: 24px;
  }

  .testCevap {
    width: 25px;
    min-height: 24px;
  }
  .cevap {
    width: 50px;
    min-height: 24px;
  }
  .hata {
    background-color: rgba(255, 0, 0, 0.2);
  }
}

.modelContainer {
  .modelLabel {
    color: #777;
    margin-bottom: 10px;
  }

  .modelInput {
    width: 100%;
    padding: 7px;
    border-radius: 0px;
    border: 1px solid #aaa;
    outline: none;
  }
}

.modalInfo {
  padding: 15px 15px 0 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.modelAlert {
  max-width: 400px;
  min-width: 320px;
  outline: none;
  padding: 32px;
  position: absolute;
  text-align: center;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.seasonContainer {
  position: relative;
  height: 1px;
  display: flex;
  justify-content: flex-end;

  .seasonRow {
    position: absolute;
    top: -75px;
  }
}

.tableSpan {
  font-weight: 400 !important;
  color: rgb(170, 170, 170);
  font-size: small;
  padding-left: 5px;
}

.tableTR {
  border-bottom: 1px solid #eee;
}

.soruContainer {
  display: flex;
  position: relative;

  .soruCevap {
    background: #ffa800;
    color: #fff;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 5px;
    width: 50px;
    height: 50px;
    font-size: 24px;
  }
}

.personContainer {
  width: 50px;
  height: 50px;
  display: flex;
  position: relative;
  background: #a0d68b;
  border-radius: 5px;
  flex-direction: column;

  .soruKisi {
    color: #fff;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    padding: 5px;
  }

  .kisiText {
    text-align: center;
    color: #fff;
    font-size: 12px;
  }
}

.cevapContainer {
  display: inline-flex;
  flex-direction: row;
  border-radius: 7px;
  height: 50px;
  text-align: right;
  overflow: hidden;

  .cevapKolon {
    min-width: 90px;
    display: flex;
    flex-direction: column;
    padding: 1px 10px;
    border-right: 1px solid #fff;
    text-align: right;
    position: relative;
    color: #fff;

    &:nth-child(1) {
      background-color: rgba(77, 185, 179, 0.5);
    }

    &:nth-child(2) {
      background-color: rgba(239, 123, 136, 0.5);
    }

    &:nth-child(3) {
      background-color: rgba(0, 0, 0, 0.1);
      color: #b9b9b9;
    }

    label {
      position: absolute;
      opacity: 0.7;
      top: 5px;
      left: 5px;
    }

    &:last-child {
      border-right: 0;;
    }

    strong {
      font-size: 18px;
    }

    span {
      font-size: 11px;
      line-height: 5px;
    }
  }

}

.siklarContainer {
  display: flex;
  flex-direction: row;
  border-radius: 7px;

  .siklarRow {
    min-width: 70px;
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1;
    padding-right: 5px;

    .siklarProgressbar {
      background-color: #e8e8e8;
      display: flex;
      flex: 1;
      position: relative;
      border-radius: 5px;
      overflow: hidden;

      .siklarProgress {
        height: 5px;
        width: 0;
        border-radius: 5px;
      }

      .dogru {
        background-color: #51ccc6
      }

      .yanlis {
        background-color: #f67280
      }
    }

    .siklarCevap {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      position: relative;
      height: 25px;

      span {
        font-size: 12px;
        position: absolute;
        bottom: 0;
        right: 0;
      }

      strong {
        font-size: 12px;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }

    &:last-child {
      padding-right: 0px;
    }

    .kisiSayisi {
      font-size: 10px;
    }
  }
}

.sureContainer {
  background: #5acff5;
  display: inline-flex;
  flex-direction: row;
  border-radius: 7px;
  height: 50px;
  text-align: right;

  .sureKolon {
    min-width: 87px;
    display: flex;
    flex-direction: column;
    padding: 1px 10px;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    color: #fff;
    text-align: right;
    position: relative;

    label {
      position: absolute;
      opacity: 0.5;
      top: 0px;
      left: -3px;
      font-size: 50px;
      font-weight: 500;
      line-height: 1;
    }

    svg {
      g [fill] {
        transition: fill 0.3s ease;
        fill: #ffffff !important;
      }
    }

    &:last-child {
      border-right: 0;;
    }

    strong {
      font-size: 16px;
      margin-top: 6px;

      span {
        font-size: 10px;
      }
    }

    span {
      font-size: 11px;
      line-height: 10px;
    }
  }
}

.loadingPage {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;

  .splash-spinner .path {
    stroke: #fff;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
}

.pointer {
  cursor: pointer;
}

.close-btn {
  padding: 0 0 0 10px;
  display: inline-block;
  position: relative;
  width: 20px;

  i {
    top: -12px;
    right: -12px;
  }
}

.unSelectedH6 {
  font-size: 1rem;
  font-family: Poppins;
  font-weight: 400;
  line-height: 1.5;
}

.dialogContainer {
  width: 550px;
  margin-top: 20px;

  input {
    width: 40px;
    border: 1px solid #ddd;

    &.inputName {
      width: 200px;
    }
  }
}

.inputLeft {
  display: inline-block;
  padding-right: 20px;
  width: 100px;
}

.questionLists {
  padding: 10px 0;

  .questionList {
    padding: 3px 4px;
    margin-top: 2px;

    &:nth-child(odd) {
      background-color: #becffb;
      border-radius: 3px;

      .duration {
        input {
          background-color: #becffb;
        }
      }

      .options {
        select {
          width: calc(100% + 3px);
          background-color: #becffb;
          padding: 5px;
          margin: -3px 0;
          border: 0;
          border-left: 1px solid #6993ff;
        }
      }
    }

    strong {
      min-width: 25px;
      display: inline-block;
      color: #6993ff;
    }

    .secenekler {
      width: 125px;
      display: inline-block;

      span {
        display: inline-block;
        border: 1px solid #6993ff;
        color: #6993ff;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        margin-right: 5px;
        text-align: center;
        cursor: pointer;
      }

      .optionE {
        display: none;
      }

      .selectedOption {
        background-color: #6993ff;
        color: #fff;
      }
    }

    .duration {
      display: inline-block;
      margin: 0 10px;
      color: #6993ff;

      input {
        width: 32px;
        padding: 0 3px;
        border: 1px solid #6993ff;
        text-align: right;
        color: #6993ff;
      }
    }

    .options {
      display: inline-block;
      width: calc(100% - 242px);
      color: #6993ff;
      margin-left: 20px;

      select {
        width: calc(100% + 5px);
        border-radius: 0 3px 3px 0;
        padding: 3px;
        margin: -3px -3px -3px 0;
        border: 1px solid #6993ff;
        text-align: right;
        color: #6993ff;
      }
    }
  }

  .errorRow {
    &:nth-child(odd) {
      background-color: #fbbebe;

      .duration {
        input {
          background-color: #fbbebe;
        }
      }

      .options {
        select {
          background-color: #fbbebe;
          border-left: 1px solid #f64e60;
        }
      }
    }

    strong {
      color: #f64e60;
    }

    .secenekler {
      span {
        border: 1px solid #f64e60;
        color: #f64e60;
      }

      .selectedOption {
        background-color: #f64e60;
      }
    }

    .duration {
      color: #f64e60;

      input {
        border: 1px solid #f64e60;
        color: #f64e60;
      }
    }

    .options {
      color: #f64e60;

      select {
        border: 1px solid #f64e60;
        color: #f64e60;
      }
    }
  }
}

.dialogAction {
  margin: 0 20px;
  border-top: 1px solid #ddd;
  padding-top: 7px;
  width: 100%;
  text-align: right;
}

.dialogLoading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.spanLink {
  cursor: pointer;
}

.spanAra {
  font-weight: 100;
  display: inline-block;
  padding: 0 5px;
  color: #888;
}

.questionRow {
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  margin-bottom: 2px;
  align-items: center;
  padding: 3px 0;

  &:nth-child(odd) {
    background-color: #e6ebff;
  }

  &:first-child {
    background-color: #6a92fb;

    strong {
      color: #fff;
    }
  }

  strong {
    display: inline-flex;
    width: 40px;
    padding: 3px 5px 3px 5px;
    justify-content: flex-end;
  }

  span {
    display: inline-flex;
    border-radius: 50%;
    border: 1px solid #6993ff;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    line-height: 0;
    margin-left: 5px;
  }

  .selectedOption {
    background-color: #6993ff;
    color: #fff;
  }

  .duration {
    width: 75px;
    justify-content: flex-end;
    display: inline-flex;
    padding-right: 10px;
  }

  .ratio {
    width: 55px;
    justify-content: flex-start;
    display: inline-flex;
    margin-left: 20px;
  }

  .durationBold {
    width: 75px;
    justify-content: flex-end;
    display: inline-flex;
    font-weight: 600;
    color: #fff;
    padding-right: 10px;
  }

  .ratioBold {
    width: 375px;
    justify-content: flex-start;
    display: inline-flex;
    font-weight: 600;
    color: #fff;
    padding-left: 20px;
  }

  .subject {
    margin-left: 30px;
    flex: 1;
  }

  .subjectBold {
    margin-left: 30px;
    display: inline-flex;
    justify-content: flex-start;
    font-weight: 600;
    color: #fff;
    flex: 1;
  }

  .optionList {
    width: 125px;
    margin-left: 5px;
  }

  .optionListBold {
    display: inline-flex;
    width: 125px;
    justify-content: flex-start;
    font-weight: 600;
    color: #fff;
    margin-left: 5px;
  }
}

.homework {
  .card-header {
    padding: 5px !important;
    background-color: #6993ff !important;
    color: #fff;
  }

  .card-body {
    .card-header {
      padding: 2px 18px 0px 15px !important;
      background-color: #fff !important;
      color: #6993ff;
    }

    .card-body {
      .homeworkResultRow {
        border-bottom: 1px solid #eee;

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }

  .testEkle {
    .testNumber {
      display: flex;
      position: absolute;
      top: 30px;
      right: 45px;
      color: #fff;
      font-weight: 600;
      font-size: 1.65rem !important;
    }

    .testTitle {
      display: flex;
      position: absolute;
      top: 5px;
      right: 45px;
      color: #fff;
      font-weight: 600;
      font-size: 1.50rem !important;
      flex-direction: column;
      text-align: right;
    }
    .testCode {
      font-size: 1rem !important;
    }

    .buttonList {
      display: flex;
      justify-content: space-between;
    }
  }

  .noneItem {
    display: none !important;
  }

  .iBlockItem {
    display: inline-block !important;
  }

  .homeworkList {
    color: #fff;
    padding: 0 5px 3px 5px;
    margin-top: 4px;
    margin-right: 30px;
    border-bottom: 1px solid #95b2ff;

    span {
      cursor: pointer;

      svg {
        margin: -4px 5px 0 -8px;
      }
    }
  }

  .saveButtonContainer {
    margin-top: 35px;
    display: flex;
    justify-content: flex-end;
    margin-right: 30px;
    margin-bottom: 30px;
  }

  .homeworkListTitle {
    color: #fff;
    padding: 0 5px;
    margin-top: 10px;
    border-radius: 5px;
    font-weight: bold;
  }
}

.indirButton {
  margin-left: 2px;

  a {
    color: #B5B5C3;
  }
}

.teachLoading {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.messageWhite {
  color: #fff;
  padding-left: 20px;
}

.homeworkResult {
  .homeworkResultContainer {
    padding: 3px 0;

    .homeworkResultRow {
      display: flex;
      padding: 3px 0;

      strong {
        flex: 3;
        display: inline-flex;
      }

      span {
        display: inline-flex;
        flex: 1
      }
    }

    .homeworkResultHeader {
      display: flex;
      padding: 5px 25px 2px 5px ;
      margin-bottom: -2px;

      strong {
        flex: 3;
        display: inline-flex;
      }

      span {
        display: inline-flex;
        flex: 1;
        font-weight: 600;
      }
    }

  }
}

.accordion {
  .listHeader {
    width: 100%;
    background-color: #FFA800;
    margin-bottom: 1px;
    border-radius: 5px;

    .MuiButton-label {
      color: #fff;
      font-weight: 600;
    }
  }
}

#xyz .MuiTabs-flexContainer {
  flex-wrap: wrap;
}

.newRowContainer {
  padding: 20px 0 0 0;
  display: flex;
  justify-content: flex-end;

  .newRowButton {
    padding: 8px 25px;
    background: #0180b5;
    color: #fff;
    border: 0;
    border-radius: 5px;
    i {
        font-size: 12px;
        color: #fff;
        margin-right: 5px;
    }
  }
}

span.detailList {
  display: inline-block;
  position: relative;
  padding: 0 5px;
}

.publishContainer h3.titles {
  margin-top: 20px;
  margin-bottom: 10px;
  color: #6a92fb;
}
.dubleButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .dubleButton {
    width: calc(50% - 14px);
    display: flex;
    cursor: pointer;

    span {
      color: #b7b7c4;
    }

    input[type="radio"] {
      display: none;
    }

    input[type="radio"]:checked + span {
      color: #FFFFFF;
      background-color: #6993FF;
      border-color: #6993FF;
    }
  }
}

.listSubject .list-group-item {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.alert-heading {
  h4 {
    margin-bottom: 0 !important;
    font-size: 14px;
  }
}
.processButton {
  width: 33.5px;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 27.5px;
}
.formAdd {
  width: calc(100% - 40px);
  margin: 0px 30px 40px 30px !important;
}
.leftControl {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 32px;
}
.examBreadCrumb {

    background: white;
    border-radius: 8px;
    padding: 12px;
    display: flex;
    flex-direction: column;

    .headerBreadCrumb{
      flex-wrap: nowrap;
      overflow-y:auto;
      padding: 6px 0;
      display: flex;

      &::-webkit-scrollbar-track
      {
        background-color: #F5F5F5;
      }

      &::-webkit-scrollbar
      {
        height: 6px;
        background-color: #F5F5F5;
      }

      &::-webkit-scrollbar-thumb
      {
        background-color: #000000;
      }

      .sheet {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 70px;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 10px;
        position: relative;
        cursor: pointer;
        border-right: 1px solid #ddd;

        &.active {
          background: #6993ff;
          color: #fff;
        }

        &:hover {
          display: inline-table;
        }
      }
    }

}